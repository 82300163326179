import React from 'react';
import { Box } from '@mui/system';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import theme from './Theme';

import Header from '../components/Header';
import Footer from '../components/Footer';
import HeroContent from '../components/HeroContent';

function Home() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box>
        <Header />
        <HeroContent />
        <Footer />
      </Box>
    </ThemeProvider>
  );
}

export default Home;
