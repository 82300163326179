import React from 'react';
import { Box } from '@mui/system';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import theme from './Theme';

import Header from '../components/Header';
import Footer from '../components/Footer';
import TermsOfUseContentV001 from '../components/TermsOfUseContent-v001';

import DocumentTitle from '../hooks/DocumentTitle';

function TermsV001() {
  DocumentTitle("Terms of use | CDR Sandbox")
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box>
        <Header />
        <TermsOfUseContentV001 />
        <Footer />
      </Box>
    </ThemeProvider>
  );
}

export default TermsV001;
