import * as React from 'react';
// import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import Link from '@mui/material/Link';

// const portalURL = 'https://portal.' + location.hostname;

function PrivacyContentV001() {
  return (
    <div className="legalContent">
      <Typography variant="h4" component="h1" sx={{ py: 2 }}>
        Privacy and information sharing
      </Typography>

      <Typography variant="body1" class="legalText">
        Any information you provide as part of using the Sandbox is provided at
        your own risk.
      </Typography>

      <Typography variant="body1" class="legalText">
        Below we set out how we will treat any information you provide to us
        when using the Sandbox.
      </Typography>

      <Typography variant="body1" class="legalHeading">
        (a) Privacy your personal information
      </Typography>

      <Typography variant="body1" class="legalText">
        We will collect your personal information (as defined in the Privacy Act
        1988 (Cth) (Privacy Act)) that you submit using the Sandbox, including
        for the purposes of identity verification.
      </Typography>

      <Typography variant="body1" class="legalText">
        Your personal information is protected by law, including the Privacy
        Act. Any use and disclosure of your personal information will occur in
        accordance with the Privacy Act.
      </Typography>

      <Typography variant="body1" class="legalText">
        We may disclose your personal information to our service providers,
        including our ICT service providers and any identity verification
        service providers, to allow them to provide services to us.
      </Typography>

      <Typography variant="body1" class="legalText">
        We, and our service providers, may further disclose your personal
        information to overseas recipients, including your mobile service
        provider or our third party identity verification service provider for
        the purposes of performing identity verification.
      </Typography>

      <Typography variant="body1" class="legalText">
        We may also use and disclose your personal information as described in
        our privacy policy, which is available at{' '}
        <Link
          href="https://www.cdr.gov.au/privacy"
          target="_blank"
          class="legalLink">
          https://www.cdr.gov.au/privacy.
        </Link>
      </Typography>

      <Typography variant="body1" class="legalText">
        This policy contains information about:
      </Typography>

      <List class="legalText">
        <li>
          how you can access and seek correction of your personal information;
        </li>
        <li>
          how you can make a complaint about breaches of the Privacy Act; and
        </li>
        <li>how we will deal with these complaints. </li>
      </List>

      <Typography variant="body1" class="legalText">
        If you wish to contact us about any privacy-related matters, you should
        contact privacy@accc.gov.au.
      </Typography>

      <Typography variant="body1" class="legalHeading">
        (b) Information sharing
      </Typography>

      <Typography variant="body1" class="legalText">
        Any information you provide may be disclosed or shared if permitted or
        required by law.
      </Typography>

      <Typography variant="body1" class="legalText">
        For more information about the ACCC/AER&apos;s general policy on
        disclosing information, please refer to the ACCC/AER Information Policy
        available at{' '}
        <Link
          href="https://www.accc.gov.au/system/files/ACCC-AER%20Information%20Policy.pdf"
          target="_blank"
          class="legalLink">
          https://www.accc.gov.au/system/files/ACCC-AER Information Policy.pdf.
        </Link>
      </Typography>
    </div>
  );
}

export default PrivacyContentV001;
