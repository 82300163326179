import * as React from 'react';
// import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import Link from '@mui/material/Link';

// const portalURL = 'https://portal.' + location.hostname;

function TermsOfUseContentV001() {
  return (
    <div className="legalContent">
      <Typography variant="h4" component="h1" sx={{ py: 2 }}>
        Terms of Use Agreement
      </Typography>

      <Typography variant="body1" class="legalText">
        In these terms of use, we refer to:
      </Typography>
      <List class="legalText">
        <li>the ACCC as &apos;we&apos; or &apos;us&apos; </li>
        <li>the CDR Sandbox as &apos;the Sandbox&apos;</li>
        <li>
          Data Holders and Accredited Data Recipients as
          &apos;Participants&apos;
        </li>
        <li>
          &apos;you&apos; as a participant who registers a Sandbox account
        </li>
        <li>
          a &apos;CDR solution&apos; to describe the solution a person wishes to
          test in the Sandbox
        </li>
        <li>
          &apos;CDR Mock Solutions&apos; to describe representations of the live
          CDR Register, Data Holders and Data Recipients in the Sandbox
        </li>
        <li>
          &apos;CDR ecosystem&apos; to describe the ecosystem of Data Holders,
          Accredited Data Recipients and consumers sharing consumer and product
          data in accordance with the{' '}
          <em>Competition and Consumer Act 2010 </em>
          (Cth) and the{' '}
          <em>Competition and Consumer (Consumer Data Right) Rules 2020 </em>
          (Cth)
        </li>
        <li>
          &apos;Test data&apos; to mean data that does not include any personal
          information about an identified individual or an individual who is
          reasonably identifiable; and data that does not include any
          person&apos;s consumer data
        </li>
      </List>

      <Typography variant="h5">
        <b>1. The Sandbox</b>
      </Typography>

      <Typography variant="body1" class="legalHeading">
        (a) What the Sandbox is
      </Typography>

      <Typography variant="body2">
        The Consumer Data Right (CDR) Sandbox is a development tool offered by
        the ACCC.
      </Typography>

      <Typography variant="body1" class="legalHeading">
        <b>What the Sandbox is</b>
      </Typography>

      <Typography variant="body1" class="legalText">
        The Sandbox is a hosted version of the CDR Mock Solutions that has been
        provided by the ACCC as a development tool to assist participants in the
        design, development, and testing of their CDR solutions in a
        non-production environment.
      </Typography>

      <Typography variant="body1" class="legalText">
        You, as a participant, can register their test solutions and interact
        with the Sandbox hosted CDR Mock Solutions. You can also discover other
        sandbox participants&apos; test CDR solutions and organise interactions
        between the test CDR solutions.
      </Typography>

      <Typography variant="body1" class="legalText">
        The Sandbox is provided free of any charge to any Sandbox participant on
        a complimentary basis as part of the ACCC&apos;s role and
        responsibilities in relation to CDR. It is not provided on a commercial
        basis.
      </Typography>

      <Typography variant="body1" class="legalText">
        For more information about the individual CDR Mock Solutions already
        available you can visit{' '}
        <Link
          href="https://www.cdr.gov.au/for-providers/participant-tooling"
          target="_blank"
          class="legalLink">
          https://www.cdr.gov.au/for-providers/participant-tooling
        </Link>
      </Typography>

      <Typography variant="subtitle2">
        <b>What happens in the Sandbox</b>
      </Typography>

      <Typography variant="body1" class="legalText">
        Following successful account creation, you can:
      </Typography>

      <List class="legalText">
        <li>
          add your CDR solution to the Sandbox Mock Register where it will
          become available to all other Sandbox participants to discover;
        </li>
        <li>
          discover other participants&apos; CDR Solutions (alongside the CDR
          Mock Solutions) via the relevant Sandbox Application Programming
          Interface (API);
        </li>
        <li>manage your Sandbox profile; and</li>
        <li>
          download certificates to assist in establishing secure connections
          with other Sandbox Participants.
        </li>
      </List>

      <Typography variant="subtitle2">
        <b>What doesn&apos;t happen in the Sandbox</b>
      </Typography>
      <Typography variant="body1" class="legalText">
        Any interactions between Sandbox participants such as Dynamic Client
        Registration and consumer data sharing occur outside of the Sandbox and
        are not monitored by the Sandbox.
      </Typography>
      <Typography variant="body1" class="legalHeading">
        (b) Our disclaimers
      </Typography>
      <Typography variant="body1" class="legalText">
        The Sandbox is not a replica of the CDR ecosystem.
      </Typography>
      <Typography variant="body1" class="legalText">
        You are responsible for:
      </Typography>

      <List class="legalText">
        <li>
          ensuring that your CDR solution will comply with CDR or other
          regulatory requirements, CDR on-boarding processes and any technical
          specifications;
        </li>
        <li>
          ensuring that your CDR solution complies with CDR and other regulatory
          requirements, CDR on-boarding processes and any technical
          specifications;
        </li>
        <li>
          ensuring that your CDR solution is commercially viable and fit for
          purpose;
        </li>
        <li>
          obtaining independent technical, legal or other appropriate expert
          advice if you would like advice on whether your CDR solution will meet
          your regulatory, technical or commercial requirements. You should not
          rely on the results of the Sandbox
        </li>
      </List>

      <Typography variant="body1" class="legalText">
        You acknowledge that in using the Sandbox we are not responsible for:
      </Typography>

      <List class="legalText">
        <li>
          ensuring the currency, accuracy, reliability or completeness of any
          information provided to you by the Sandbox;
        </li>
        <li>
          ensuring the mock solutions in the Sandbox will meet Consumer Data
          Standards at all times.
        </li>
      </List>

      <Typography variant="body1" class="legalText">
        You also acknowledge that in using the Sandbox, we make no
        representation or warranty to you, express or implied, that your CDR
        solution will work or behave in the same way in the CDR ecosystem.
      </Typography>

      <Typography variant="body1" class="legalText">
        You must not describe your CDR solution as “ACCC approved” or otherwise
        imply in any way that the ACCC endorses their product, service,
        methodology or business model after using the Sandbox.
      </Typography>

      <Typography variant="h5">
        <b>2. Why terms of use are important?</b>
      </Typography>

      <Typography variant="body1" class="legalText">
        By registering for a Sandbox account and using the Sandbox, you agree to
        be bound by these terms of use. Importantly, this means making sure you
        know and follow your responsibilities.
      </Typography>

      <Typography variant="body1" class="legalText">
        Please read these terms of use carefully as they set out a number of key
        requirements relating to your use of the Sandbox.
      </Typography>

      <Typography variant="h5">
        <b>3. Changes to terms of use</b>
      </Typography>

      <Typography variant="body1" class="legalText">
        When we make changes to these terms of use, you will need to accept the
        new terms of use to continue using your Sandbox account. We will publish
        notice of any changes to the terms of use on our website.
      </Typography>

      <Typography variant="body1" class="legalText">
        New terms of use will replace any Sandbox terms of use you have
        previously accepted.
      </Typography>

      <Typography variant="h5">
        <b>4. Who can have a Sandbox account?</b>
      </Typography>

      <Typography variant="body1" class="legalText">
        To have a Sandbox account you:
      </Typography>

      <List class="legalText">
        <li>
          must be a &quot;natural person&quot; i.e. an individual human being,
          not a corporate entity, a robot or a software program etc; and
        </li>
        <li>
          have your own email address (because an email address can only be used
          once to create a Sandbox account).
        </li>
      </List>

      <Typography variant="h5">
        <b>5. Your responsibilities in relation to your Sandbox account</b>
      </Typography>

      <Typography variant="body1" class="legalText">
        It is important to understand your responsibilities when using your
        Sandbox account.
      </Typography>

      <Typography variant="body1" class="legalText">
        If you do not comply with the responsibilities set out below, we may
        lock, suspend or terminate your Sandbox account and in some instances,
        it is possible you could face civil or criminal penalties.
      </Typography>

      <Typography variant="body1" class="legalText">
        If you do not keep your personal information secure, it is possible
        someone could try and pretend to be you online and use your account.
      </Typography>

      <Typography variant="body1" class="legalHeading">
        (a) Do not use any person&apos;s data or personal information
      </Typography>

      <Typography variant="body1" class="legalText">
        You must only use test data in the Sandbox.
      </Typography>

      <Typography variant="body1" class="legalText">
        You are responsible for ensuring that no person&apos;s consumer data,
        personal information or other information is disclosed when using the
        Sandbox.
      </Typography>

      <Typography variant="body1" class="legalHeading">
        (b) Provide correct, complete and up to date information
      </Typography>

      <Typography variant="body1" class="legalText">
        You are responsible for making sure your personal details (such as your
        name, contact details) are correct and up to date in your Sandbox
        account. If your details change, you should login to the Sandbox account
        to update your details.
      </Typography>

      <Typography variant="body1" class="legalText">
        You understand that providing false or misleading information or
        documents (including forms) to us can have serious legal consequences
        and may result in termination of your account.
      </Typography>

      <Typography variant="body1" class="legalHeading">
        (c) Keep your Sandbox account safe
      </Typography>

      <Typography variant="body1" class="legalText">
        You are responsible for your Sandbox account and making sure only you
        can access it. You must not allow someone else to login or use your
        Sandbox account.
      </Typography>

      <Typography variant="body1" class="legalText">
        You must keep your Sandbox account user name and password safe. Do not
        share these details with anyone else.
      </Typography>

      <Typography variant="body1" class="legalText">
        If you do not take reasonable precautions to keep your Sandbox account
        details safe, for example, if you give someone your details so that they
        can login to your account, you are responsible for everything that
        person does with your Sandbox account.
      </Typography>

      <Typography variant="body1" class="legalHeading">
        (d) Something does not seem right? Tell us!
      </Typography>

      <Typography variant="body1" class="legalText">
        If you think that someone else has accessed your Sandbox account, you
        need to let us know as soon as possible. You agree that we may monitor
        your access to and use of the Sandbox.
      </Typography>

      <Typography variant="body1" class="legalText">
        You will change your password immediately if you think someone else has
        become aware of it or if there is an unauthorised access of your
        account.
      </Typography>

      <Typography variant="body1" class="legalHeading">
        (e) Do not access another person&apos;s Sandbox account
      </Typography>

      <Typography variant="body1" class="legalText">
        You are not allowed to access another person&apos;s Sandbox account
        under any circumstances.
      </Typography>

      <Typography variant="body1" class="legalHeading">
        (f) Banned uses of your Sandbox account
      </Typography>

      <Typography variant="body1" class="legalText">
        You are not allowed to use the Sandbox, or use your Sandbox account:
      </Typography>

      <List class="legalText">
        <li>
          to participate in any illegal or fraudulent activity, including
          impersonating a person other than you in the Sandbox;
        </li>
        <li>to cause disruption to the Sandbox or Sandbox participants;</li>
        <li>
          in a way that infringes a person&apos;s rights or prevents them from
          using the Sandbox;
        </li>
        <li>
          in any way that may harass, cause distress or inconvenience others;
        </li>
        <li>
          to record unlawful, defamatory, offensive or scandalous material; or
        </li>
        <li>to record material that breaks or encourages breaking the law.</li>
      </List>

      <Typography variant="body1" class="legalHeading">
        (g) Do not monitor or copy the Sandbox
      </Typography>

      <Typography variant="body1" class="legalText">
        You are not allowed to use any software (like bots, scraper tools etc.)
        to access, monitor or copy the Sandbox or its contents unless we have
        given you prior written permission.
      </Typography>

      <Typography variant="h5">
        <b>6. When your access can be taken away</b>
      </Typography>

      <Typography variant="body1" class="legalText">
        Your Sandbox account may be locked, suspended or terminated:
      </Typography>

      <List class="legalText">
        <li>if you do not comply with your responsibilities;</li>
        <li>you do not comply with these Sandbox terms of use; or</li>
        <li>by us at any time and for any reason.</li>
      </List>

      <Typography variant="body1" class="legalHeading">
        (a) Closure of inactive accounts
      </Typography>

      <Typography variant="body1" class="legalText">
        In some cases, we may also close accounts that have not been used after
        a certain amount of time. We may also do so when your Sandbox account
        has been permanently locked.
      </Typography>

      <Typography variant="body1" class="legalText">
        If your account has been closed and you wish to resume using the
        Sandbox, you will need to create a new Sandbox account.
      </Typography>

      <Typography variant="h5">
        <b>7. What we are responsible for</b>
      </Typography>

      <Typography variant="body1" class="legalText">
        We are responsible for:
      </Typography>

      <List class="legalText">
        <li>
          taking reasonable steps to ensure the security of your personal
          information while it is being collected by us, stored or passing
          through the Sandbox;
        </li>
        <li>
          making sure the links from your Sandbox account to any other systems
          (e.g. technical resources required to operate the ecosystem) are
          secure;
        </li>
        <li>
          handling your personal information in accordance with the Privacy Act;
          and
        </li>
        <li>
          taking reasonable care when providing you with information about the
          Sandbox.
        </li>
      </List>

      <Typography variant="h5">
        <b>8. What we are not responsible for</b>
      </Typography>

      <Typography variant="body1" class="legalText">
        We are not responsible for:
      </Typography>

      <List class="legalText">
        <li>ensuring the Sandbox is error free at all times;</li>
        <li>ensuring any defects with the Sandbox will be fixed;</li>
        <li>ensuring that you have continuous access to the Sandbox;</li>
        <li>
          providing any support, advice or other assistance in relation to your
          use of the Sandbox;
        </li>
        <li>
          ensuring that the CDR Mock Solutions in the Sandbox meet Consumer Data
          Standards at all times;
        </li>
        <li>
          ensuring that other Sandbox participants&apos; CDR solutions will
          comply with CDR or other regulatory requirements, CDR on-boarding
          processes or any technical specification;
        </li>
        <li>
          to the extent that is lawful, any Loss (defined below) you suffer as a
          result of your use, or your inability to use, the Sandbox;
        </li>
        <li>
          to the extent that is lawful, any Loss suffered by a third party as a
          result of your use, or your inability to use, the Sandbox; and
        </li>
        <li>
          any interactions you have with other Sandbox participants while using
          the Sandbox, including in relation to what data you exchange.
        </li>
      </List>

      <Typography variant="body1" class="legalHeading">
        In these Terms of Use Agreement:
      </Typography>

      <Typography variant="body1" class="legalText">
        &quot;Loss&quot; means any loss, damage, cost or expense (to any person
        or property), including loss of profits or revenue, loss or denial of
        opportunity, loss of use, loss of data, and any indirect, remote,
        abnormal or unforeseeable loss.
      </Typography>

      <Typography variant="h5">
        <b>9. Rights we grant you</b>
      </Typography>

      <Typography variant="body1" class="legalText">
        We give you permission, in the form of a non-transferable,
        non-exclusive, revocable licence to use the Sandbox on your device(s)
        (for example, your computer or mobile), so long as you comply with these
        terms of use and any other applicable laws.
      </Typography>

      <Typography variant="body1" class="legalText">
        The Sandbox software is provided &apos;as is&apos;, without warranty of
        any kind, express or implied, including in relation to the Sandbox being
        of acceptable quality or fit for any particular purpose.
      </Typography>

      <Typography variant="h5">
        <b>10. Applicable law</b>
      </Typography>

      <Typography variant="body1" class="legalText">
        The laws of the Australian Capital Territory, Australia (ACT) govern
        these Terms of Use Agreement.
      </Typography>
    </div>
  );
}

export default TermsOfUseContentV001;
