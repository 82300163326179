import * as React from 'react';
// import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

// const portalURL = 'https://portal.' + location.hostname;

function AttestationContent() {
  return (
    <div className="legalContent">
      <Typography variant="h4" component="h1" sx={{ py: 2 }}>
        Attestation
      </Typography>

      <Typography variant="body1" class="legalText">
        For explicit acknowledgment and agreement before registration and use of
        the Sandbox.
      </Typography>

      <Typography variant="body1" class="legalHeading">
        I acknowledge and agree that I will only use test data in the Sandbox
        and when sharing data with other Sandbox participants and that I will
        not use or disclose any person’s consumer data, personal information or
        other data.
      </Typography>

      <Typography variant="body1" class="legalHeading">
        I also acknowledge and agree that in using the Sandbox other Sandbox
        participants will be able to see, discover and interact with my CDR
        solution.
      </Typography>
    </div>
  );
}

export default AttestationContent;
