import React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}>
      {'© Commonwealth of Australia'}
    </Typography>
  );
}

const footers = [
  {
    title: 'Resources',
    links: [
      {
        url: 'https://cdr.gov.au',
        text: 'cdr.gov.au',
        target: '_blank',
      },
      {
        url: 'https://consumerdataright.atlassian.net/l/cp/VnNrL8sZ',
        text: 'Documentation',
        target: '_blank',
      },
      {
        url: 'https://hub.docker.com/u/consumerdataright',
        text: 'CDR Docker Hub',
        target: '_blank',
      },
    ],
  },
  {
    title: 'GitHub',
    links: [
      {
        url: 'https://github.com/ConsumerDataRight/',
        text: 'Consumer Data Right',
        target: '_blank',
      },
      {
        url: 'https://github.com/ConsumerDataRight/mock-register',
        text: 'Mock Register',
        target: '_blank',
      },
      {
        url: 'https://github.com/ConsumerDataRight/mock-data-holder',
        text: 'Mock Data Holder',
        target: '_blank',
      },
      {
        url: 'https://github.com/ConsumerDataRight/mock-data-holder-energy',
        text: 'Mock Data Holder Energy',
        target: '_blank',
      },
      {
        url: 'https://github.com/ConsumerDataRight/mock-data-recipient',
        text: 'Mock Data Recipient',
        target: '_blank',
      },
    ],
  },
  {
    title: 'Legal',
    links: [
      {
        url: 'terms-of-use',
        text: 'Terms of use',
        target: '',
      },
      {
        url: 'privacy-and-information-sharing',
        text: 'Privacy and information sharing',
        target: '',
      },
      {
        url: 'attestation',
        text: 'Attestation',
        target: '',
      },
      {
        url: 'https://www.cdr.gov.au/disclaimer-copyright',
        text: 'Disclaimer & copyright',
        target: '_blank',
      },
      {
        url: 'https://www.cdr.gov.au/accessibility',
        text: 'Accessibility',
        target: '_blank',
      },
      {
        url: 'https://foi.accc.gov.au/',
        text: 'Freedom of information',
        target: '_blank',
      },
    ],
  },
];

function Footer() {
  return (
    <Container
      maxWidth="lg"
      component="footer"
      sx={{
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        py: [3, 6],
      }}>
      <Grid container spacing={3} justifyContent="space-evenly">
        {footers.map((footer) => (
          <Grid item xs={6} sm={3} key={footer.title}>
            <Typography
              variant="h6"
              component="h2"
              color="text.primary"
              gutterBottom>
              {footer.title}
            </Typography>
            <ul className="footerLinks">
              {footer.links.map((item) => (
                <li key={item.text}>
                  <Link
                    href={item.url}
                    target={item.target}
                    rel="noopener"
                    variant="subtitle1"
                    color="text.secondary">
                    {item.text}
                  </Link>
                </li>
              ))}
            </ul>
          </Grid>
        ))}
      </Grid>
      <Copyright sx={{ mt: 2 }} />
    </Container>
  );
}

export default Footer;
