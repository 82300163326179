import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    background: {
      default: '#FFF',
    },
    mode: 'light',
  },
  typography: {
    fontFamily: 'freight-sans-pro, Trebuchet MS, sans-serif',
    fontSize: 15,
  },
});

export default theme;
