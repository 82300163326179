import React from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import Terms from './pages/Terms-Latest';
import TermsV001 from './pages/Terms-v001';
import Privacy from './pages/Privacy-Latest';
import PrivacyV001 from './pages/Privacy-v001';
import Attestation from './pages/Attestation';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/terms-of-use" element={<Terms />} />
      <Route path="/terms-of-use-v001" element={<TermsV001 />} />
      <Route path="/privacy-and-information-sharing" element={<Privacy />} />
      <Route path="/privacy-and-information-sharing-v001" element={<PrivacyV001 />}/>
      <Route path="/attestation" element={<Attestation />} />
      <Route path="*" element={<Home />} /> {/* NB: this '*' catch-all route should be last (displays homepage for unknown routes) */}
    </Routes>
  );
}

export default App;
