import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import HeaderLogo from '../images/logo/svg/CDRSandbox_Primary_RGB_White.svg';

function Header() {
  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: '#002760',
      }}>
      <Container>
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="div"
            width="100px"
            sx={{ mr: 2, pt: 1 }}>
            <a href="/">
              <img src={HeaderLogo} alt="CDR Sandbox" />
            </a>
          </Typography>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Header;
