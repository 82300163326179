import * as React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

const portalURL = 'https://portal.' + location.hostname;

function HeroContent() {
  return (
    <div className="hero">
      <div className="heroOverlay"></div>
      <Container
        className="heroContent"
        sx={{
          color: '#fff',
          height: '80vh',
          justifyContent: 'center',
          display: 'flex',
        }}>
        <Box
          sx={{
            margin: 0,
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            maxWidth: '600px',
            width: '100%',
            textAlign: 'center',
          }}>
          <Typography
            variant="h4"
            component="h1"
            sx={{ py: 2 }}>
            A sandbox for your CDR solutions
          </Typography>
          <Typography variant="body1" component="span">
            The CDR sandbox is a free service available to anyone interested in
            the CDR, test your solutions with our mock solutions or other
            sandbox participants.
          </Typography>
          <Typography>
            <Button
              variant="contained"
              sx={{ backgroundColor: '#262B4F', mt: 2 }}
              href={portalURL}>
              Portal
            </Button>
          </Typography>
        </Box>
      </Container>
    </div>
  );
}

export default HeroContent;
